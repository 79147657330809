import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from "./pages/Home";
import { Controls } from "./pages/Controls";
import { Screen } from "./pages/Screen";
import { Rate } from "./pages/Rate";
import { Busy } from "./pages/Busy";
import { Final } from "./pages/Final";
import {SocketProvider} from "./context/SocketContext";

const App = () => {
  return (
    <SocketProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/controls" element={<Controls />} />
            <Route path="/screen" element={<Screen />} />
            <Route path="/busy" element={<Busy />} />
            <Route path="/rate" element={<Rate />} />
            <Route path="/final" element={<Final />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </SocketProvider>
  );
};

export default App;
