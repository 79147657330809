import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from './styles.module.css';
import {useNavigate} from "react-router";
import {useSocket} from "../../context/SocketContext";
import {Modal} from "@mui/material";

export const Controls = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const onConnect = () => {
    setIsLoading(true);
    if (socket) {
      socket.emit('try-connection')

      socket.on('connection_error', () => {
        setIsLoading(false);
        navigate('/busy');
      })

      socket.on('try-connection-allow', () => {
        setIsLoading(false);
        setIsConfirmModalVisible(true);
      })
    }
  }

  return (
    <div className={styles.Controls}>
      <div className={styles.Content}>
        <Card className={styles.Description}>
          <CardContent className={styles.DescriptionContent}>
            <Typography>
              <span className={styles.DescriptionTitle}>Интерактивный 3Д-макет проекта</span> <br />«Дом на Зорге»
            </Typography>

            <Typography component="p">
              «Дом на Зорге» — проект бизнес-класса, возводится в престижном районе «Сокол» с исторической сталинской застройкой и статусными жилыми комплексами, достойно продолжая градостроительную традицию этой части столицы и обладая при этом неповторимым обликом.
            </Typography>

            <Typography component="p">
              Сложившаяся инфраструктура района включает крупные торгово-развлекательные центры, спортивные комплексы, стадионы, медицинские и бизнес-центры.
            </Typography>
          </CardContent>
        </Card>

        <div className={styles.Right}>
          <Card className={styles.HowTo}>
            <CardContent className={styles.HowToContent}>
              <Typography>
                Как управлять
              </Typography>

              <Typography component="div">
                <ul className={styles.HowToList}>
                  <li>чтобы выйти из интерактивного прототипа нажмите F12</li>
                  <li>используйте стрелки или кнопки WASD для перемещения</li>
                  <li>используйте мышку для того, чтобы осмотреться вокруг</li>
                </ul>
              </Typography>
            </CardContent>
          </Card>

          <figure className={styles.ControlsImageHolder}>
            <img className={styles.ControlsImage} src="/assets/images/controls.png" alt="controls"/>
          </figure>
        </div>
      </div>

      <div className={styles.Bottom}>
        <Button
          disabled={isLoading}
          variant="contained"
          className={styles.SubmitButton}
          onClick={onConnect}
        >
          {isLoading ? 'Проверка подключения ...' : 'Подключиться к серверу'}
        </Button>
      </div>

      <Modal
          className={styles.ConfirmModal}
          open={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(true)}
      >
        <Card className={styles.ConfirmModalCard}>
          <CardContent className={styles.ConfirmModalContent}>
            <Typography>
              Хотите начать просмотр?
            </Typography>

            <div className={styles.ConfirmModalActions}>
              <Button
                className={styles.ConfirmModalActionStart}
                variant="contained"
                onClick={() => {
                  const el = document.querySelector('#root')
                  if (el) {
                    el.requestPointerLock();
                    el.requestFullscreen();
                  }
                  navigate('/screen')
                }}
              >
                Да
              </Button>

              <Button
                  className={styles.ConfirmModalActionExit}
                  variant="contained"
                  onClick={() => {
                    setIsConfirmModalVisible(false)
                  }}
              >
                Нет
              </Button>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  )
}
