import Card from '@mui/material/Card';
import { useNavigate } from "react-router";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from './styles.module.css';


export const Home = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.Home}>
      <div className={styles.Content}>
        <Card className={styles.Description}>
          <CardContent className={styles.DescriptionContent}>
            <Typography>
            <span className={styles.DescriptionTitle}>Интерактивный 3Д-макет проекта</span> <br />«Дом на Зорге»
            </Typography>

            <Typography component="p">
              «Дом на Зорге» — проект бизнес-класса, возводится в престижном районе «Сокол» с исторической сталинской застройкой и статусными жилыми комплексами, достойно продолжая градостроительную традицию этой части столицы и обладая при этом неповторимым обликом.
            </Typography>

            <Typography component="p">
              Сложившаяся инфраструктура района включает крупные торгово-развлекательные центры, спортивные комплексы, стадионы, медицинские и бизнес-центры.
            </Typography>
          </CardContent>
        </Card>

        <Card className={styles.Form}>
          <CardContent className={styles.FormContent}>
            <Typography>
              Расскажите немного о себе
            </Typography>

            <TextField
              className={styles.TextField}
              label="Как вас зовут"
              variant="filled"
              size="small"
            />

            <TextField
                className={styles.TextField}
              label="Ваш номер телефона"
              variant="filled"
              size="small"
            />

            <Button
              variant="contained"
              className={styles.SubmitButton}
              onClick={() => {
                navigate('/controls')
              }}
            >
              Продолжить
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
