import Card from '@mui/material/Card';
import { useNavigate } from "react-router";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import styles from './styles.module.css';
import {IconButton} from "@mui/material";
import {useState} from "react";


export const Final = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.Rate}>
      <div className={styles.Content}>
        <Card className={styles.Description}>
          <CardContent className={styles.DescriptionContent}>
            <Typography>
            <span className={styles.DescriptionTitle}>Интерактивный 3Д-макет проекта</span> <br />«Дом на Зорге»
            </Typography>

            <Typography component="p">
              «Дом на Зорге» — проект бизнес-класса, возводится в престижном районе «Сокол» с исторической сталинской застройкой и статусными жилыми комплексами, достойно продолжая градостроительную традицию этой части столицы и обладая при этом неповторимым обликом.
            </Typography>

            <Typography component="p">
              Сложившаяся инфраструктура района включает крупные торгово-развлекательные центры, спортивные комплексы, стадионы, медицинские и бизнес-центры.
            </Typography>
          </CardContent>
        </Card>

        <Card className={styles.Form}>
          <CardContent className={styles.FormContent}>
            <Typography className={styles.FormTitle}>
              Спасибо что воспользовались нашим интерактивным прототипом
            </Typography>

            <Typography>
              Желаем вам хорошего дня!
            </Typography>

            <Button
              variant="contained"
              className={styles.SubmitButton}
              onClick={() => {
                window.location.href = 'https://google.com';
              }}
            >
              Вернуться на сайт застройщика
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
