import Card from '@mui/material/Card';
import { useNavigate } from "react-router";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import styles from './styles.module.css';
import {IconButton} from "@mui/material";
import {useState} from "react";

type RatingProps = {
  value: number
  onChange: (value: number) => void
}

const Rating = (props: RatingProps) => {
  return (
    <div className={styles.Rating}>
      <IconButton onClick={() => props.onChange(1)}>
        <StarIcon className={`${styles.RatingStar} ${props.value >= 1 && styles.RatingStarActive}`} />
      </IconButton>
      <IconButton onClick={() => props.onChange(2)}>
        <StarIcon className={`${styles.RatingStar} ${props.value >= 2 && styles.RatingStarActive}`} />
      </IconButton>
      <IconButton onClick={() => props.onChange(3)}>
        <StarIcon className={`${styles.RatingStar} ${props.value >= 3 && styles.RatingStarActive}`} />
      </IconButton>
      <IconButton onClick={() => props.onChange(4)}>
        <StarIcon className={`${styles.RatingStar} ${props.value >= 4 && styles.RatingStarActive}`} />
      </IconButton>
      <IconButton onClick={() => props.onChange(5)}>
        <StarIcon className={`${styles.RatingStar} ${props.value >= 5 && styles.RatingStarActive}`} />
      </IconButton>
    </div>
  )
}

export const Rate = () => {
  const navigate = useNavigate();
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);

  return (
    <div className={styles.Rate}>
      <div className={styles.Content}>
        <Card className={styles.Description}>
          <CardContent className={styles.DescriptionContent}>
            <Typography>
            <span className={styles.DescriptionTitle}>Интерактивный 3Д-макет проекта</span> <br />«Дом на Зорге»
            </Typography>

            <Typography component="p">
              «Дом на Зорге» — проект бизнес-класса, возводится в престижном районе «Сокол» с исторической сталинской застройкой и статусными жилыми комплексами, достойно продолжая градостроительную традицию этой части столицы и обладая при этом неповторимым обликом.
            </Typography>

            <Typography component="p">
              Сложившаяся инфраструктура района включает крупные торгово-развлекательные центры, спортивные комплексы, стадионы, медицинские и бизнес-центры.
            </Typography>
          </CardContent>
        </Card>

        <Card className={styles.Form}>
          <CardContent className={styles.FormContent}>
            <Typography className={styles.FormTitle}>
              Спасибо что воспользовались нашим интерактивным прототипом
            </Typography>

            <Typography>
              Прежде, чем вы закроете вкладку, поделитесь вашим мнением!
            </Typography>

            <Typography>
              Как вы оцениваете интерактивный прототип?
            </Typography>

            <Rating value={rating1} onChange={(value) => setRating1(value)} />

            <Typography>
              Как вы оцениваете проект “Дом на Зорге”?
            </Typography>

            <Rating value={rating2} onChange={(value) => setRating2(value)} />

            <Button
              variant="contained"
              className={styles.SubmitButton}
              onClick={() => {
                navigate('/final')
              }}
            >
              Поделиться мнением
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
