import { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import { useNavigate } from "react-router";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Modal} from "@mui/material";
import Button from '@mui/material/Button';

import {useSocket} from "../../context/SocketContext";


import styles from './styles.module.css';

export const Busy = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  useEffect(() => {
    if (socket) {
      socket.emit('try-connection')

      socket.on('try-connection-allow', () => {
        setIsConfirmModalVisible(true);
      })
    }
  }, [navigate, socket]);

  return (
    <div className={styles.Busy}>
      <div className={styles.Content}>
        <Card className={styles.Description}>
          <CardContent className={styles.DescriptionContent}>
            <Typography>
              <span className={styles.DescriptionTitle}>Интерактивный 3Д-макет проекта</span> <br />«Дом на Зорге»
            </Typography>

            <Typography component="p">
              «Дом на Зорге» — проект бизнес-класса, возводится в престижном районе «Сокол» с исторической сталинской застройкой и статусными жилыми комплексами, достойно продолжая градостроительную традицию этой части столицы и обладая при этом неповторимым обликом.
            </Typography>

            <Typography component="p">
              Сложившаяся инфраструктура района включает крупные торгово-развлекательные центры, спортивные комплексы, стадионы, медицинские и бизнес-центры.
            </Typography>
          </CardContent>
        </Card>

        <Card className={styles.Form}>
          <CardContent className={styles.FormContent}>
            <Typography className={styles.FormTitle}>
              В настоящее время все сервера заняты
            </Typography>

            <Typography>
              Примерное время ожидания составляет ~10 минут.
              Не закрывайте вкладку, чтобы дождаться своей очереди.
            </Typography>

            <Typography>
              Хотите, чтобы мы отправили вам уведомление (в виде PUSH-уведомления), как только всё будет готово для старта?
            </Typography>

            <Button
              variant="contained"
              className={styles.NotifyMeButton}
              onClick={() => {}}
            >
              Отправьте мне уведомление, по готовности для подключения!
            </Button>

            <Typography>
              Мы отправим вам уведомление, как всё будет готово.
            </Typography>
          </CardContent>
        </Card>
      </div>

      <div className={styles.Footer}>
        <Button
          disabled
          variant="contained"
          className={styles.SubmitButton}
        >
          Ожидаем готовности одного из серверов...
        </Button>
      </div>

      <Modal
          className={styles.ConfirmModal}
          open={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(true)}
      >
        <Card className={styles.ConfirmModalCard}>
          <CardContent className={styles.ConfirmModalContent}>
            <Typography>
              Хотите начать просмотр?
            </Typography>

            <div className={styles.ConfirmModalActions}>
              <Button
                  className={styles.ConfirmModalActionStart}
                  variant="contained"
                  onClick={() => {
                    const el = document.querySelector('#root')
                    if (el) {
                      el.requestPointerLock();
                      el.requestFullscreen();
                    }
                    navigate('/screen')
                  }}
              >
                Да
              </Button>

              <Button
                  className={styles.ConfirmModalActionExit}
                  variant="contained"
                  onClick={() => {
                    setIsConfirmModalVisible(false);
                    navigate('/controls');
                  }}
              >
                Нет
              </Button>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  )
}
