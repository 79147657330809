
import {useCallback, useEffect, useRef, useState} from "react";
import _ from 'lodash';
import styles from './styles.module.css';
import {Modal} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import {useSocket} from "../../context/SocketContext";

const MOUSE_EVENTS_DEBOUNCE = 25;
const KEYBOARD_EVENTS_DEBOUNCE = 50;

export const Screen = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const peerConnectionRef = useRef<RTCPeerConnection>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const navigate = useNavigate();
  const socket = useSocket();

  const isLoaded = isLoading === false;

  useEffect(() => {
    if (socket) {
      socket.emit('try-connection')

      socket.on('connection_error', () => {
        navigate('/busy');
      })
    }
  }, [navigate, socket]);

  const setupPeerConnection = useCallback(() => {
    (peerConnectionRef.current as RTCPeerConnection) = new RTCPeerConnection({
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' }
      ]
    });

    console.log('peerConnection.localDescription', peerConnectionRef.current)

    if (peerConnectionRef.current) {
      peerConnectionRef.current.onicecandidate = (event) => {
        if (event.candidate && socket) {
          console.log('Sending ICE candidate to server:', event.candidate);
          socket.emit('icecandidate', event.candidate);
        }
      };

      peerConnectionRef.current.ontrack = (event) => {
        console.log('Received track from server', event.streams);
        const [stream] = event.streams;

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play().then(() => {
            console.log('Video playback started successfully');
          }).catch((error) => {
            console.error('Error starting video playback:', error);
          });
        }
      };

      console.log('Peer connection setup complete');
    }
  }, [socket]);

  const onOffer = useCallback(async (offer: RTCSessionDescriptionInit) => {
    console.log('Received offer from server:', offer);

    if (!peerConnectionRef.current) {
      console.warn('PeerConnection is not initialized, reinitializing...');
      setupPeerConnection();
    }

    try {
      if (peerConnectionRef.current && socket) {
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));
        console.log('Remote description set successfully');

        const answer = await peerConnectionRef.current.createAnswer();
        await peerConnectionRef.current.setLocalDescription(answer);
        console.log('Sending answer to server');
        socket.emit('answer', answer);
      }
    } catch (error) {
      console.error('Error processing offer:', error);
    }
  }, [setupPeerConnection, socket])

  const onIceCandidate = useCallback(async (candidate: RTCIceCandidateInit) => {
    console.log('Received ICE candidate from server:', candidate);

    if (peerConnectionRef.current) {
      try {
        await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
        console.log('ICE candidate added successfully');
      } catch (error) {
        console.error('Error adding ICE candidate:', error);
      }
    } else {
      console.warn('PeerConnection is not initialized. Ignoring ICE candidate.');
    }
  }, [])

  const initSocketListeners = useCallback(() => {
    if (socket) {
      socket.on('offer', onOffer);

      socket.on('icecandidate', onIceCandidate);
    }
  }, [onOffer, onIceCandidate, socket]);

  const getEventProperties = useCallback((event: MouseEvent) => {
    if (videoRef.current) {
      // Get the video element dimensions and position
      const rect = videoRef.current.getBoundingClientRect();

      // Calculate click position relative to the video element
      const clickX = event.clientX - rect.left;
      const clickY = event.clientY - rect.top;

      // Calculate video dimensions as displayed
      const { videoWidth, videoHeight } = videoRef.current;
      const videoAspectRatio = videoWidth / videoHeight;
      const containerAspectRatio = rect.width / rect.height;

      let scale, offsetX, offsetY;

      // Adjust for potential black bars due to object-fit: contain
      if (videoAspectRatio > containerAspectRatio) {
        // Video is wider than container
        scale = rect.width / videoWidth;
        offsetX = 0;
        offsetY = (rect.height - videoHeight * scale) / 2;
      } else {
        // Video is taller than container
        scale = rect.height / videoHeight;
        offsetX = (rect.width - videoWidth * scale) / 2;
        offsetY = 0;
      }

      // Map click coordinates to normalized video coordinates
      const normalizedX = (clickX - offsetX) / (videoWidth * scale);
      const normalizedY = (clickY - offsetY) / (videoHeight * scale);

      // Ensure coordinates are clamped between 0 and 1
      const clampedX = Math.max(0, Math.min(1, normalizedX));
      const clampedY = Math.max(0, Math.min(1, normalizedY));

      return {
        x: clampedX,
        y: clampedY,
        videoWidth,
        videoHeight,
        _meta: {
          isEdgedLeft: clampedX < 0.05,
          isEdgedRight: clampedX > 0.95,
          isEdgedTop: clampedY < 0.05,
          isEdgedBottom: clampedY > 0.95,
        }
      }
    }

    return null
  }, []);

  const debouncedMouseDown = _.throttle((event: MouseEvent) => {
    if (socket) {
      socket.emit("mouseDown");
    }
  }, MOUSE_EVENTS_DEBOUNCE);

  const debouncedMouseUp = _.throttle((event: MouseEvent) => {
    if (socket) {
      socket.emit("mouseUp");
    }
  }, MOUSE_EVENTS_DEBOUNCE);

  const debouncedMouseMove = _.throttle((event: MouseEvent) => {
    const properties = getEventProperties(event);
    if (properties && socket) {
      socket.emit("mouseMove", { ...properties });
      console.log(`Mouse move: x=${properties.x}, y=${properties.y}, videoWidth=${properties.videoWidth}, videoHeight=${properties.videoHeight}`);
    }
  }, MOUSE_EVENTS_DEBOUNCE);

  const debouncedRelativeMouseMove = _.throttle((event: MouseEvent) => {
    if (socket) {
      socket.emit("mouseRelativeMove", { movementX: event.movementX * 6, movementY: event.movementY * 6 });
      console.log(`Mouse relatively moved by: x=${event.movementX}, y=${event.movementY}`);
    }
  }, MOUSE_EVENTS_DEBOUNCE);

  const debouncedDoubleClick = _.throttle((event) => {
    if (socket) {
      socket.emit("mouseDoubleClick");
    }
  }, MOUSE_EVENTS_DEBOUNCE);

  const debouncedWheel = _.throttle((event: WheelEvent) => {
    if (socket) {
      socket.emit("wheel", {deltaY: event.deltaY});
    }
  }, MOUSE_EVENTS_DEBOUNCE);

  const debouncedContextMenu = _.throttle((event: MouseEvent) => {
    event.preventDefault();
    const properties = getEventProperties(event);

    if (properties && socket) {
      socket.emit("mouseClick", {
        ...properties,
        type: 'right'
      });

      console.log(`Mouse right click: x=${properties.x}, y=${properties.y}, videoWidth=${properties.videoWidth}, videoHeight=${properties.videoHeight}`);
    }
  }, MOUSE_EVENTS_DEBOUNCE);

  const debouncedKeyDown = _.debounce((event: KeyboardEvent) => {
    if (socket) {
      socket.emit('keyDown', {
        keyCode: event.keyCode,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
        altKey: event.altKey,
      });

      console.log(`Key down: `, {
        keyCode: event.keyCode,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
        altKey: event.altKey,
      });
    }
  }, KEYBOARD_EVENTS_DEBOUNCE);

  const debouncedKeyUp = _.debounce((event: KeyboardEvent) => {
    if (socket) {
      socket.emit('keyUp', {
        keyCode: event.keyCode,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
        altKey: event.altKey,
      });

      console.log(`Key up: `, {
        keyCode: event.keyCode,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
        altKey: event.altKey,
      });
    }
  }, KEYBOARD_EVENTS_DEBOUNCE)

  const onFullScreenChange = useCallback(() => {
    setIsPaused(!document.fullscreenElement)
  }, [])

  useEffect(() => {
    if (!isPaused) {
      document.addEventListener('mousemove', debouncedRelativeMouseMove);
    } else {
      document.removeEventListener('mousemove', debouncedRelativeMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', debouncedMouseMove);
      document.removeEventListener('mousemove', debouncedRelativeMouseMove);
    };
  }, [isPaused]);

  const addVideoListeners = useCallback(() => {
    document.addEventListener('keydown', debouncedKeyDown);
    document.addEventListener('keyup', debouncedKeyUp);
    document.addEventListener('contextmenu', debouncedContextMenu);

    document.addEventListener('mousedown', debouncedMouseDown);
    document.addEventListener('mouseup', debouncedMouseUp);
    document.addEventListener('dblclick', debouncedDoubleClick);
    document.addEventListener('wheel', debouncedWheel);
  }, [debouncedKeyDown, debouncedKeyUp, debouncedContextMenu, debouncedMouseDown, debouncedMouseUp, debouncedDoubleClick, debouncedWheel]);


  useEffect(() => {
    setupPeerConnection();
    initSocketListeners();

    document.addEventListener('fullscreenchange', onFullScreenChange);

    return () => {
      document.removeEventListener('mousedown', debouncedMouseDown);
      document.removeEventListener('mouseup', debouncedMouseUp);
      document.removeEventListener('dblclick', debouncedDoubleClick);
      document.removeEventListener('wheel', debouncedWheel);

      document.removeEventListener('keydown', debouncedKeyDown);
      document.removeEventListener('keyup', debouncedKeyUp);
      document.removeEventListener('contextmenu', debouncedContextMenu);
      document.removeEventListener('fullscreenchange', onFullScreenChange);

      if (socket) {
        socket.off('offer', onOffer);
        socket.off('icecandidate', onIceCandidate);
      }
    }
  }, [socket, initSocketListeners, setupPeerConnection]);

  const onVideoLoad = () => {
    if (videoRef.current) {
      addVideoListeners();
      setIsLoading(false);
    }
  }

  return (
    <div className={`${styles.Screen} ${isLoaded && styles.ScreenLoaded}`}>
      {!isLoaded && (
        <div className={styles.Loader}>
          <div className={styles.LoaderContent}>
            Loading...
          </div>
        </div>
      )}
      <video
        ref={videoRef}
        id="videoElement"
        autoPlay
        muted
        playsInline
        controls={false}
        onLoadedMetadata={onVideoLoad}
        className={`${styles.Video} ${styles.VideoFullScreen}`}
      />

      <Modal
        className={styles.ExitModal}
        open={isPaused}
        onClose={() => setIsPaused(true)}
      >
        <Card className={styles.ExitCard}>
          <CardContent className={styles.ExitCardContent}>
            <Typography>
              Хотите заверишь просмотр?
            </Typography>

            <div className={styles.ExitModalActions}>
              <Button
                className={styles.ExitModalActionContinue}
                variant="contained"
                onClick={() => {
                  setIsPaused(false);
                  const el = document.querySelector('#root')
                  if (el) {
                    el.requestPointerLock();
                    el.requestFullscreen();
                  }
                }}
              >
                Нет, вернуться обратно
              </Button>
              <Button
                className={styles.ExitModalActionExit}
                variant="contained"
                onClick={() => {
                  navigate('/rate')
                }}
              >
                Да, завершить
              </Button>
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  )
}
